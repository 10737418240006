/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Accordion, Button, Card } from 'react-bootstrap-v5'
import clsx from 'clsx'
import { languages } from '../../../_metronic/partials/layout/header-menus/Languages'
import { useLang } from '../../../_metronic/i18n/Metronici18n'


interface MyCourseProps {
    info: any;
}
const MyCourseDetailOverview: FC<MyCourseProps> = (props: MyCourseProps) => {
    const intl = useIntl()
    let isEmpty = true;


    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)
    if (currentLanguage?.lang !== "en") {
        for (let index = 0; index < props.info.length; index++) {
            const element = props.info[index];
            if (currentLanguage && element.translations && element.translations[currentLanguage?.lang]) {
                isEmpty = false;

            }

        }
    }

    const [navbar, setNavbar] = useState('overview');
    const [faq, setFaq] = useState(0);

    const selectFaq = (faqIndex: any) => {

        if (faq === faqIndex) setFaq(0);
        else setFaq(faqIndex);

    }
    return (
        <div className='myCourse_overview row'>
            <div className='col-lg-6'>
                {console.log("current Language", currentLanguage)}
                {(props.info && props.info.length > 0) ?
                    props.info.map((infoObject: any) => {
                        return <>
                            {currentLanguage && infoObject.translations && infoObject.translations[currentLanguage?.lang] && currentLanguage.lang !== "en" &&

                                <div className='myCourse_courseOverview'>
                                    <h2 className='long'>{infoObject.translations[currentLanguage?.lang].name}</h2>
                                    <div className="" dangerouslySetInnerHTML={{ __html: infoObject.translations[currentLanguage?.lang].description }}></div>
                                </div>


                            }

                            {currentLanguage && currentLanguage.lang === "en" &&
                                <div className='myCourse_courseOverview'>
                                    <h2 className='long'>{infoObject.name}</h2>
                                    <div className="" dangerouslySetInnerHTML={{ __html: infoObject.description }}></div>
                                </div>}
                        </>
                    }
                    )
                    :
                    <div className='myCourse_courseOverview'>
                        <h2 className='long'>{intl.formatMessage({ id: 'POPLEADS.MENU_OVERVIEW' })}</h2>
                        <div className="">
                            <p>  {intl.formatMessage({ id: 'POPLEADS.NO_CONTENT' })}</p>
                        </div>
                    </div>

                }

                {currentLanguage && currentLanguage.lang !== "en" && isEmpty &&

                    <div className='myCourse_courseOverview'>
                        <h2 className='long'>{intl.formatMessage({ id: 'POPLEADS.MENU_OVERVIEW' })}</h2>
                        <div className="">
                            <p>  {intl.formatMessage({ id: 'POPLEADS.NO_CONTENT' })}</p>
                        </div>
                    </div>
                }
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 columnRight'>
                <img
                    id='illusRight'
                    alt=''
                    src={toAbsoluteUrl('/media/illustrations/coursedetails.png')}
                />
                <div className='myCourse_FAQ'>
                    <h2 className='short'>{intl.formatMessage({ id: 'POPLEADS.FAQ' })}</h2>
                    <Accordion className='myCourse_FAQItems'>
                        <Card>
                            <Card.Header >

                                <Accordion.Toggle onClick={() => selectFaq(1)} className='d-flex justify-content-between w-100' as={Button} variant="link" eventKey="1">

                                    <div className={clsx(faq !== 1 && 'withEllipsis')}>
                                        {intl.formatMessage({ id: 'POPLEADS.FAQ.QUESTION_1' })}
                                    </div>

                                    <div
                                        className='d-flex div-faqPlus'>
                                        <KTSVG
                                            path='/media/svg/popleads/faqPlus.svg'
                                            className='faqPlus'
                                        />
                                    </div>
                                </Accordion.Toggle>

                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>{intl.formatMessage({ id: 'POPLEADS.FAQ.ANSWER_1' })}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle onClick={() => selectFaq(2)} className='d-flex justify-content-between w-100' as={Button} variant="link" eventKey="2">
                                    <div className={clsx(faq !== 2 && 'withEllipsis')}>{intl.formatMessage({ id: 'POPLEADS.FAQ.QUESTION_2' })}</div>
                                    <div
                                        className='d-flex div-faqPlus'>
                                        <KTSVG
                                            path='/media/svg/popleads/faqPlus.svg'
                                            className='faqPlus'
                                        />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>{intl.formatMessage({ id: 'POPLEADS.FAQ.ANSWER_2' })} </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle onClick={() => selectFaq(3)} className='d-flex justify-content-between w-100' as={Button} variant="link" eventKey="3">
                                    <div className={clsx(faq !== 3 && 'withEllipsis')}> {intl.formatMessage({ id: 'POPLEADS.FAQ.QUESTION_3' })} </div>
                                    <div
                                        className='d-flex div-faqPlus'>
                                        <KTSVG
                                            path='/media/svg/popleads/faqPlus.svg'
                                            className='faqPlus'
                                        />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>{intl.formatMessage({ id: 'POPLEADS.FAQ.ANSWER_3' })} </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle onClick={() => selectFaq(4)} className='d-flex justify-content-between w-100' as={Button} variant="link" eventKey="4">

                                    <div className={clsx(faq !== 4 && 'withEllipsis')}>{intl.formatMessage({ id: 'POPLEADS.FAQ.QUESTION_4' })} </div>
                                    <div
                                        className='d-flex div-faqPlus'>
                                        <KTSVG
                                            path='/media/svg/popleads/faqPlus.svg'
                                            className='faqPlus'
                                        />
                                    </div>

                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>{intl.formatMessage({ id: 'POPLEADS.FAQ.ANSWER_4' })}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle onClick={() => selectFaq(5)} className='d-flex justify-content-between w-100' as={Button} variant="link" eventKey="5">

                                    <div className={clsx(faq !== 5 && 'withEllipsis')}>{intl.formatMessage({ id: 'POPLEADS.FAQ.QUESTION_5' })}</div>

                                    <div
                                        className='d-flex justify-content-end div-faqPlus'>
                                        <KTSVG
                                            path='/media/svg/popleads/faqPlus.svg'
                                            className='faqPlus'
                                        />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>{intl.formatMessage({ id: 'POPLEADS.FAQ.ANSWER_5' })}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </div>
            {/*  <div className='col-lg-12'>
                <div className='trainerContainer d-flex'>
                    <div className='row'>
                        <div className='col-md-5 d-flex align-items-end'>

                            <div className='trainerName'>Trainer<br />Martha Megan</div>
                            <img
                                alt='Trainer'
                                src={toAbsoluteUrl('/media/stock/trainer.png')}
                            />
                        </div>
                        <div className='col-md-7 d-flex align-items-end'>
                            <p>
                                “Lorem Ipsum is simply dummy text.”
                                <br />
                                <br />
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export { MyCourseDetailOverview }
