/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { ResetPassword } from './components/ResetPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useLang, setLanguage } from "../../../_metronic/i18n/Metronici18n" //'../../../../../i18n/Metronici18n'
import { languages } from "../../../_metronic/partials/layout/header-menus/Languages"

export function AuthPage() {
  const intl = useIntl()
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const html = document.getElementsByTagName("html")[0]
  if(currentLanguage && currentLanguage.lang === 'ar') html.setAttribute("dir","rtl")
  
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed login-wrapper container'>
      {/* begin::Content */}
      <div className='d-flex align-items-center flex-column-fluid p-10 pb-lg-20 login-page'>
        {/* begin::Logo */}
        <div className="row flex-1">
          <div className='flex-1 d-flex login-image col-xl-7'>
            <div className='flex-column'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/stc_pay_purple_for_login_page.png')}
                className='logo mb-10'
              />
              <p className="login-wel-message mt-3">{intl.formatMessage({ id: 'POPLEADS.WELCOME' })}</p>
              <h1 className="login-message mt-3">
                {intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM_N' })}
              </h1>
              <p className="login-gray-message mt-5">{intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM_LOGIN' })}</p>
            </div>
          </div>
          <div className="col-xl-5 flex-column">
            <div className='login-box'>
              <Switch>
                <Route path='/auth/login' component={Login} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Route path='/auth/reset-password' component={ResetPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
            <div className='col-xl-12 flex-column '>
              <div className='login-footer fs-6 d-flex flex-row justify-content-between'>
                <div className='left'>
                  Popleads ©2021  |  <a href="mailto:elearning@ta3leem.com">{intl.formatMessage({ id: 'POPLEADS.HELP' })}</a>
                </div>
                <div className='changeLanguageAuth'>
                  {currentLanguage && currentLanguage.lang !== "en" ?
                    <span onClick={() => {
                      setLanguage("en")
                    }}>
                      English
                    </span>
                    :
                    <span onClick={() => {
                      setLanguage("ar")
                    }}>
                      العربية
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
