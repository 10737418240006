/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

const Landing: FC = () => {
    const intl = useIntl()
    const html = document.getElementsByTagName("html")[0]
    const isRTL = html.getAttribute("dir") === "rtl"

    return (
        <>
            <div className='row landing'>
                <div className='col-lg-7 d-flex flex-column justify-content-center'>
                    <h1>
                        <span className='wel-text'>{intl.formatMessage({ id: 'POPLEADS.WELCOME_TO' })}</span>
                        <img     
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/stc_pay_purple_for_home.png')}
                            className='logo'
                        />
                        <span>{intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM' })}</span>
                    </h1>
                    <p className="login-gray-message mt-5">{intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM_HOME' })}</p>

                    <div className='marketIcons'>
                        <a href='https://play.google.com/store/apps/details?id=com.popleads.ta3leembusiness&hl=en_SG&gl=US' target="_blank">
                            {isRTL ?
                                <KTSVG
                                    path='/media/svg/popleads/googlePlay_RTL.svg'
                                    className='marketIcon'
                                />
                                :
                                <KTSVG
                                    path='/media/svg/popleads/googlePlay.svg'
                                    className='marketIcon'
                                />
                            }
                        </a>
                        <a href="https://apps.apple.com/us/app/ta3leem-for-business/id1500897240" target="_blank">
                            {isRTL ?
                                <KTSVG
                                    path='/media/svg/popleads/appStore_RTL.svg'
                                    className='marketIcon'
                                />
                                :
                                <KTSVG
                                    path='/media/svg/popleads/appStore.svg'
                                    className='marketIcon'
                                />
                            }

                        </a>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <img
                        id='illusRight'
                        alt=''
                        src={toAbsoluteUrl('/media/illustrations/homepage.png')}
                        className='mt-5 mt-lg-0'
                    />
                </div>
            </div>

        </>
    )
}

export { Landing }
