/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import clsx from 'clsx'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {RootState} from '../../../setup'
import {useFormik} from 'formik'
import {post} from '../networking/requestService'

const initialValues = {
  oldPassword: '',
  newPassword: '',
}

const MyAccount: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_8'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH_50'}))
      .required(intl.formatMessage({id: 'POPLEADS.OLD_PASSWORD_REQIRED'})),
    newPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_8'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH_50'}))
      .required(intl.formatMessage({id: 'POPLEADS.NEW_PASSWORD_REQIRED'})),
  })
  const [loading, setLoading] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [isRequested, setIsRequested] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const requestObj = {
        fn: 'setLoggedinUserPassword',
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }
      setIsRequested(false)

      post(requestObj)
        .then(({data}) => {
          setIsRequested(false)
          setLoading(false)
          setStatus(data.text)
        })
        .catch((error) => {
          console.log('Error:', error.response.data.text)
          setLoading(false)
          setSubmitting(false)
          setStatus(error.response.data.text)
        })
    },
  })

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const isRTL = document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"

  return (
    <>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed login-wrapper'>
        <div className='row flex-1 pt-15'>
          <div className='col-xl-6 col-md-6 flex-column'>
            <div className='title-account mt-7'>
              <span className='profile-title pt-10'>
                {intl.formatMessage({id: 'POPLEADS.PROFILE'})}
              </span>
            </div>

            <div className='profile-box row mt-10 '>
              <div className='col-xl-6 col-sm-6'>
                <p className='user-name-pro'> {user.name} </p>
                <p>{user.company.name}</p>
                <p> {user.profile.baslik} </p>
              </div>
              <div className='col-xl-6 col-sm-6'>
                <p>{user.profile.tel}</p>
                <p> {user.profile.email} </p>
                <p>{user.profile.country}</p>
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-md-6 flex-1 flex-column'>
            <div className='account-box'>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_account_changePasswors_form'
              >
                <div className='text mb-10'>
                  <h1 className='text mb-3 title-login-form'>
                    {' '}
                    {intl.formatMessage({id: 'POPLEADS.CHANGE_PASSWORD'})}
                  </h1>
                </div>

                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}
                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  {formik.touched.oldPassword && formik.errors.oldPassword && (
                    <div className='fv-plugins-message-container mb-3'>
                      <span className='error-alert' role='alert'>
                        {formik.errors.oldPassword}
                      </span>
                    </div>
                  )}
                  <div className={clsx(
                    'd-flex login-field',
                    {
                      'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword,
                    },
                    {
                      'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                    }
                  )}
                  >
                    <input
                      id='input-login'
                      placeholder={intl.formatMessage({id: 'POPLEADS.OLD_PASSWORD'})}
                      style={{position: 'relative', width: '100%'}}
                      {...formik.getFieldProps('oldPassword')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword,
                        },
                        {
                          'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                        }
                      )}
                      type={passwordShown ? 'text' : 'password'}
                      name='oldPassword'
                      autoComplete='off'
                  />
                    {passwordShown ? (
                      <svg className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')} onClick={togglePassword} xmlns='http://www.w3.org/2000/svg' width='23.708' height='23.708' viewBox='0 0 23.708 23.708'>
                        <g id='Icon_feather-eye-off' data-name='Icon feather-eye-off' transform='translate(-0.086 -0.086)'>
                          <path id='Path_154' data-name='Path 154' d='M17.578,19.233a9.556,9.556,0,0,1-5.638,1.956C5.3,21.189,1.5,13.594,1.5,13.594A17.514,17.514,0,0,1,6.3,7.956M9.947,6.228A8.654,8.654,0,0,1,11.94,6c6.644,0,10.44,7.594,10.44,7.594a17.562,17.562,0,0,1-2.05,3.028m-6.378-1.016a2.848,2.848,0,1,1-4.024-4.025' transform='translate(0 -1.654)' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                          <path id='Path_155' data-name='Path 155' d='M1.5,1.5,22.38,22.38' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                        </g>
                      </svg>
                    ) : (
                      <svg className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')} onClick={togglePassword} xmlns='http://www.w3.org/2000/svg' width='22.884' height='17.188' viewBox='0 0 22.884 17.188'>
                        <g id='Icon_feather-eye' data-name='Icon feather-eye' transform='translate(-0.5 -5)'>
                          <path id='Path_149' data-name='Path 149' d='M1.5,13.594S5.3,6,11.942,6s10.442,7.594,10.442,7.594-3.8,7.594-10.442,7.594S1.5,13.594,1.5,13.594Z' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                          <path id='Path_150' data-name='Path 150' d='M19.2,16.348A2.848,2.848,0,1,1,16.348,13.5,2.848,2.848,0,0,1,19.2,16.348Z' transform='translate(-4.406 -2.754)' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
                <div className='fv-row mb-10'>
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className='fv-plugins-message-container mb-3'>
                      <span className='error-alert' role='alert'>
                        {formik.errors.newPassword}
                      </span>
                    </div>
                  )}
                  <div className={clsx(
                    'd-flex login-field',
                    {
                      'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                    },
                    {
                      'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                    }
                  )}
                  >
                    <input
                      id='input-login'
                      placeholder={intl.formatMessage({id: 'POPLEADS.NEW_PASSWORD'})}
                      style={{position: 'relative', width: '100%'}}
                      {...formik.getFieldProps('newPassword')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                        },
                        {
                          'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                        }
                      )}
                      type={passwordShown ? 'text' : 'password'}
                      name='newPassword'
                      autoComplete='off'
                    />
                    {passwordShown ? (
                      <svg className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')} onClick={togglePassword} xmlns='http://www.w3.org/2000/svg' width='23.708' height='23.708' viewBox='0 0 23.708 23.708'>
                        <g id='Icon_feather-eye-off' data-name='Icon feather-eye-off' transform='translate(-0.086 -0.086)'>
                          <path id='Path_154' data-name='Path 154' d='M17.578,19.233a9.556,9.556,0,0,1-5.638,1.956C5.3,21.189,1.5,13.594,1.5,13.594A17.514,17.514,0,0,1,6.3,7.956M9.947,6.228A8.654,8.654,0,0,1,11.94,6c6.644,0,10.44,7.594,10.44,7.594a17.562,17.562,0,0,1-2.05,3.028m-6.378-1.016a2.848,2.848,0,1,1-4.024-4.025' transform='translate(0 -1.654)' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                          <path id='Path_155' data-name='Path 155' d='M1.5,1.5,22.38,22.38' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                        </g>
                      </svg>
                    ) : (
                      <svg className={clsx('eye-icon', isRTL ? 'eye-rtl' : '')} onClick={togglePassword} xmlns='http://www.w3.org/2000/svg' width='22.884' height='17.188' viewBox='0 0 22.884 17.188'>
                        <g id='Icon_feather-eye' data-name='Icon feather-eye' transform='translate(-0.5 -5)'>
                          <path id='Path_149' data-name='Path 149' d='M1.5,13.594S5.3,6,11.942,6s10.442,7.594,10.442,7.594-3.8,7.594-10.442,7.594S1.5,13.594,1.5,13.594Z' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                          <path id='Path_150' data-name='Path 150' d='M19.2,16.348A2.848,2.848,0,1,1,16.348,13.5,2.848,2.848,0,0,1,19.2,16.348Z' transform='translate(-4.406 -2.754)' fill='none' stroke='#8e9aa0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-small  w-100 mb-5'
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'POPLEADS.CHANGE'})}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'POPLEADS.PLEASE_WAIT'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Action */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {MyAccount}
