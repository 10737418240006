import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux"
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { Link } from 'react-router-dom'
import { useLang, setLanguage } from '../../../i18n/Metronici18n'
import { languages } from '../../../partials/layout/header-menus/Languages'
import { post } from '../../../../app/popleads/networking/requestService'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1 toolbarBell',
  toolbarGridIconSizeClass = 'svg-icon-1 toolbarGrid'

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  console.log("user: ", user);
  const [mobileMenu, setMobileMenu] = useState(false);
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const html = document.getElementsByTagName("html")[0]
  if (currentLanguage && currentLanguage.lang === 'ar') html.setAttribute("dir", "rtl")



  const getArLanguage = () => {
    post({
      "fn": "updateAccountLanguage",
      "language": "ar"
    }).then(() => {
      console.log("istek")
    })
  }
  const getEnLanguage = () => {
    post({
      "fn": "updateAccountLanguage",
      "language": "en"
    }).then(() => {
      console.log("istek")
    })
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

      {/* For mobile langue */}
      <div className='changeLanguage d-flex d-sm-none'>
        {currentLanguage && currentLanguage.lang !== "en" ?
          <span onClick={() => {
            setLanguage("en")
            getEnLanguage()
          }}>
            English
          </span>
          :
          <span onClick={() => {
            setLanguage("ar")
            getArLanguage()
          }}>
            العربية
          </span>
        }
      </div>

      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div>
        {/* Activities 
        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          {/* begin::Drawer toggle
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          {/* end::Drawer toggle 
        </div>
        */}
        {/* Quick links 
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper 
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
          {/* end::Menu wrapper 
        </div>
        */}
        {/* CHAT 
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper 
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div>
          {/* end::Menu wrapper 
        </div>
        */}

        {/* NOTIFICATIONS */}
        {/*
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper 
          <div
            className={clsx(
              'btn btn-icon position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/svg/popleads/bell.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderNotificationsMenu />
          
        </div>
        */}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol d-flex align-items-center', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            id='userDropdown'
          >
            <span className='toolbarName'>{user.profile.ad}</span>
            <KTSVG
              path='/media/icons/duotune/arrows/awesome-caret-down.svg'
              className='toolbarArrow'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        <div id='userDropdownMobile' onClick={() => setMobileMenu(!mobileMenu)} className={clsx(mobileMenu && 'active-mobile-menu' )}>
          <KTSVG
            path='/media/svg/popleads/mobile-menu-icon-2.svg'
            className={toolbarGridIconSizeClass}
          />
        </div>
        {mobileMenu && <div className='mobileMenuContainer'>
          <div className='welcome-text'>Welcome {user.profile.ad}!</div>
          <Link to="/my-account" onClick={() => setMobileMenu(false)}><div className='d-flex flex-column align-items-center'>My Account<span>Account Settings and more</span></div></Link>
          <Link to="/logout" onClick={() => setMobileMenu(false)}><div>Sign Out</div></Link>
        </div>}
        {/* end::User */}
      </div>
      
      {/* For dekstop langue */}
      <div className='changeLanguage d-none d-sm-flex'>
        {currentLanguage && currentLanguage.lang !== "en" ?
          <span onClick={() => {
            setLanguage("en")
            getEnLanguage()
          }}>
            English
          </span>
          :
          <span onClick={() => {
            setLanguage("ar")
            getArLanguage()
          }}>
            العربية
          </span>
        }
      </div>
    </div>
  )
}

export { Topbar }
