/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';


const Store: FC = () => {
    const template = `<!doctype html>
    <html lang="en">
    
    <head>
        <meta charset="utf-8">
        <!-- <meta http-equiv="refresh" content="1;url=http://example.com"> -->
        <script type="text/javascript">
            /**
             * Determine the mobile operating system.
             * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
             *
             * @returns {String}
             */
            function getMobileOperatingSystem() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // Windows Phone must come first because its UA also contains "Android"
                if (/windows phone/i.test(userAgent)) {
                    return "Windows Phone";
                }
                if (/android/i.test(userAgent)) {
                    return "Android";
                }
                // iOS detection from: http://stackoverflow.com/a/9039885/177710
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return "iOS";
                }
                return "unknown";
            }
            const mobileOS = getMobileOperatingSystem();
            console.log('mobileOS', mobileOS);
            // http://localhost:4200/qr-code/store.html
            const host = window.location.host;
            window.location.href = (mobileOS === 'Android' ? 'https://play.google.com/store/apps/details?id=com.popleads.ta3leembusiness' :
                mobileOS === 'iOS' ? 'https://apps.apple.com/us/app/ta3leem-for-business/id1500897240' : '/');
        </script>
        <title>Page Redirection</title>
    </head>
    
    <body>
        You will be redirected automatically...
    </body>
    
    </html>`;

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: template }}></div>
        </>
    )
}

export { Store }
