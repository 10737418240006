import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl'
import { post } from '../../../popleads/networking/requestService';
import { SimpleModal } from '../../../popleads/modals/reset-password/SimpleModal';


export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [error, setError] = useState<any>(undefined)
    const [passwordShown, setPasswordShown] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [isChangeSuccess, setIsChangeSuccess] = useState(false);
    const search = useLocation().search;
    const intl = useIntl()
    const email = new URLSearchParams(search).get('email');
    const codeParam = new URLSearchParams(search).get('codeParam');

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'AUTH.VALIDATION.WRONG_EMAIL' }))
            .min(3, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_3' }))
            .max(50, intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_50' }))
            .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_EMAIL' })),
        password: Yup.string()
            .min(3, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_3' }))
            .max(50, intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_50' }))
            .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_PASSWORD' })),
        confirmPassword: Yup.string()
            .min(3, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_3' }))
            .max(50, intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_50' }))
            .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED.CONFIRM_PASSWORD' })),
    })

    const initialValues = {
        email,
        password: '',
        confirmPassword: ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
             setTimeout(() => {
                 if (values.password != values.confirmPassword) {
                     setError("Password and confirm password are not the same!");
                     setHasErrors(true);
                     setLoading(false)
                     setSubmitting(false)
                     return;
                 }
                 setLoading(true);
                 let requestObj = {
                     fn: 'changePassword',
                     email,
                     newPassword: values.password,
                     codeParam
                 }
                 post(requestObj)
                     .then(() => {
                         setLoading(false);
                         setIsPasswordChanged(true);
                     })
                     .catch((error) => {
                         console.log("error:", error.response.data.text)
                         setError(error.response.data.text)
                         setHasErrors(true)
                         setLoading(false)
                         setSubmitting(false)
                         setStatus('The login detail is incorrect')
                     })
             }, 1000)
        },
    });

    const togglePassword = () => {
        setPasswordShown(!passwordShown)
    };

    return (
        <>
            {/* {isPasswordChanged ? <Redirect to="/auth/login" /> */}
            {isPasswordChanged &&
            <SimpleModal
                title="Success"
                successButton="Ok"
                centered = {true}
                bodyClass={"isPasswordChangeModalBody"}
                modalBody={intl.formatMessage({ id: 'POPLEADS.YOU_CHANGED_YOUR_PASSWORD' })}
                onClose={(resp: string) => {   
                    if(resp === 'OK'){
                        setIsPasswordChanged(false);
                        setIsChangeSuccess(true);
                    }
                }}
            /> }
            {isChangeSuccess && 
             <Redirect to="/auth/login" />
            }
            
                <form
                    className='form w-100'
                    noValidate
                    id='kt_login_signin_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='text mb-10'>
                        {/* begin::Title */}
                        <h1 className='text mb-3 title-login-form'>{intl.formatMessage({ id: 'AUTH.GENERAL.RESET_BUTTON' })}</h1>
                        {/* end::Title */}
                    </div>

                    {/* begin::Title */}
                    {hasErrors === true && error && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>
                                {error}
                            </div>
                        </div>
                    )}

                    {hasErrors === false && (
                        <div className='mb-10 bg-light-info p-8 rounded'>
                            <div className='text-info'>{intl.formatMessage({ id: 'AUTH.GENERAL.FORGOT_MESSAGE' })}</div>
                        </div>
                    )}
                    {/* end::Title */}

                    {/* begin::Form group */}
                    <div className='fv-row mb-6'>
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container mb-3'>
                                <div className='fv-help-block'>
                                    <span className='error-alert' role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}
                        <input
                            id='input-login'
                            placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                            disabled={true}
                            {...formik.getFieldProps('email')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                { 'is-invalid': formik.touched.email && formik.errors.email },
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                        />
                    </div>

                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container mb-3'>
                            <div className='fv-help-block'>
                                <span className='error-alert' role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                    <input
                        id='input-login'
                        type={passwordShown ? 'text' : 'password'}
                        placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
                        autoComplete='off'
                        style={{ position: 'relative' }}
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    {passwordShown ? (
                        <i className='fa fa-eye-slash eye-icon' onClick={togglePassword} />
                    ) : (
                        <i className='fa fa-eye eye-icon' onClick={togglePassword} />
                    )}
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container mb-3'>
                            <div className='fv-help-block'>
                                <span className='error-alert' role='alert'>{formik.errors.confirmPassword}</span>
                            </div>
                        </div>
                    )}
                    <input
                        id='input-login'
                        type={passwordShown ? 'text' : 'password'}
                        placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
                        autoComplete='off'
                        style={{ position: 'relative' }}
                        {...formik.getFieldProps('confirmPassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                            },
                            {
                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                            }
                        )}
                    />
                    {passwordShown ? (
                        <i className='fa fa-eye-slash eye-icon' onClick={togglePassword} />
                    ) : (
                        <i className='fa fa-eye eye-icon' onClick={togglePassword} />
                    )}
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='d-flex flex-wrap justify-content-between pb-lg-0'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-lg  fw-bolder me-4'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && <span style={{ color: 'white' }} className='indicator-label'>{intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block', color: 'white' }}>
                                    {intl.formatMessage({ id: 'POPLEADS.PLEASE_WAIT' })}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>


                        <Link
                            to='/auth/login'
                            className='forgot-password-text'
                        >
                            {intl.formatMessage({ id: 'AUTH.GENERAL.CANCEL_BUTTON' })}
                        </Link>


                    </div>
                    {/* end::Form group */}
                </form>
            
        </>
    )
}
