/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC } from 'react'

type Props = {
    value: number
}

const ProgressBarCircle: FC<Props> = ({ value }) => {
    const leftValue = value > 50 ? (value - 50) * 180 / 50 : 0;
    const left = "rotate(" + leftValue + "deg)";
    const rightValue = value < 50 ? value * 180 / 50 : 180;
    const right = "rotate(" + rightValue + "deg)";
    return (
        <div className='d-inline-block'>
            <div className="progressC">
                <span className="progress-left">
                    <span className="progressBar" style={{ transform: left }}></span>
                </span>
                <span className="progress-right">
                    <span className="progressBar" style={{ transform: right }}></span>
                </span>
                <div className="progress-value">{value}%</div>
            </div>
            <div className='progress-completed'>Completed</div>
        </div>
    )
}

export { ProgressBarCircle }

