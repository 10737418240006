import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap-v5";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from './../../../../_metronic/helpers/AssetHelpers';

interface Props {
    className?: string;
    bodyClass?: string
    title?: string;
    onClose?: (resp: string) => void | any;
    bodySvgUrl?: string;
    errorMessage?: string;
    successButton?: string;
    primaryButton?: string;
    buttonWithDisableColor?: string;
    centered?: boolean;
    modalBody?: React.ReactNode | string;
}

export const SimpleModal = (props: Props) => {
    const [show, setShow] = useState<boolean>(true);

        const handleClose = (resp: string) => {
            props.onClose!(resp);
        };
        const handleShow = () => setShow(true);

        const [errorMessage, setErrorMessage] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        
    return (
        <Modal centered={props.centered} animation={false} enforceFocus={false} show={show} onHide={() => handleClose("Cancel")}>
                    <Modal.Header >
                        <Modal.Title className="simpleModalTitle page-heading">{props.title && props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${props.className ? props.className : ""}`}>
                        {props.bodySvgUrl && (
                            <span
                                className={`svg-icon-6x `}>
                                <SVG title="Info" src={toAbsoluteUrl(props.bodySvgUrl)} />
                            </span>
                        )}
                        {props.modalBody && 
                            <div className={`simpleModalBody ${props.bodyClass ? props.bodyClass : ""}`}>{props.modalBody}</div>
                        }
                        {props.errorMessage && <span className="errorMessage">{props.errorMessage}</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        {props.buttonWithDisableColor && (
                            <button className="disable-color font-size-14 font-weight-semibold cancelButton"  onClick={() => handleClose("Cancel")}>{props.buttonWithDisableColor}</button>
                        )
                        }
                        {props.primaryButton && (
                            <Button variant="primary" className="saveButtonModal" onClick={() => handleClose("OK")}>
                                {props.primaryButton}
                            </Button>
                        )}
                        {props.successButton && (
                            <Button
                                variant="success"
                                className="successOkBtn"
                                onClick={() => handleClose("OK")}>
                                {props.successButton}
                            </Button>
                        )}

                    </Modal.Footer>
                </Modal>
    )
}
