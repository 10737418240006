/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';


interface ErrorProps {
    text: any
    code: number
}

const Error: FC<ErrorProps> = (props: ErrorProps) => {
    const intl = useIntl();
    console.log('props = ', props);
    return (
        <div className="errorBox">
            <i className={"error-icon flaticon2-warning icon-4x errorIcon " + (props.code === 500 ? "text-danger" : "text-warning")}></i>
            <div style={{ fontSize: '48px' }}>Error</div>
            <div style={{ fontSize: '24px' }}>{props.text}</div>
        </div>
    )
}

export { Error }
