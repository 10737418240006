/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const CustomErrorPage: React.FC = () => {
  const intl = useIntl()

  return (
    <div className='errorPage'>
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/logos/stc_pay_purple-1.png')}
        className='logo-default'
      />
      <Switch>
        <Route path='/error/404' exact={true}>
          <KTSVG path='/media/svg/popleads/404.svg' />
          <h1 className='text-center'>{intl.formatMessage({id: 'POPLEADS.404_TITLE'})}</h1>
          <p className='text-center'>{intl.formatMessage({id: 'POPLEADS.404_TEXT'})}</p>
          <Link className='mx-auto mx-lg-0' to='/'>
            <button>{intl.formatMessage({id: 'POPLEADS.GOTO_ALLCOURSES'})}</button>
          </Link>
        </Route>
        <Route path='/error/500' exact={true}>
          <KTSVG path='/media/svg/popleads/500.svg' />
          <h1 className='text-center'>{intl.formatMessage({id: 'POPLEADS.500_TITLE'})}</h1>
          <p className='text-center'>{intl.formatMessage({id: 'POPLEADS.500_TEXT'})}</p>
          <Link className='mx-auto mx-lg-0' to='/'>
            <button>{intl.formatMessage({id: 'POPLEADS.GOTO_ALLCOURSES'})}</button>
          </Link>
        </Route>
        <Redirect from='/error' exact={true} to='/error/404' />
        <Redirect to='/error/404' />
      </Switch>
    </div>
  )
}

export {CustomErrorPage}
