/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

const AllCoursesNoCourse: FC = () => {
    const intl = useIntl()

    return (
        <>
            <div className='row landing noCourse'>
                <h1>{intl.formatMessage({ id: 'POPLEADS.NO_COURSE' })}</h1>
                <p> {intl.formatMessage({ id: 'POPLEADS.NO_COURSE_INFO' })}</p>
            </div>
        </>
    )
}

export { AllCoursesNoCourse }
