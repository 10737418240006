import React, { useEffect } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { Link, useLocation } from 'react-router-dom'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import { MenuComponent } from "../../_metronic/assets/ts/components";
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { MenuItem } from './components/header/MenuItem'

const MasterLayout: React.FC = ({ children }) => {
  const { classes } = useLayout()
  const intl = useIntl()
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            { // <Toolbar />  //popleads commented 
            }
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AsideDefault />
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/*<ExploreMain />*/}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
      <div className='mobile-menu'>
        <Link to='/' className={clsx(window.location.pathname === "/" && 'selectedWithBorder')}>{intl.formatMessage({ id: 'POPLEADS.ALL_COURSES' })}</Link>
        <Link to='/my-courses' className={clsx(window.location.pathname === "/my-courses"  && 'selectedWithBorder')}>{intl.formatMessage({ id: 'POPLEADS.MY_COURSES' })}</Link>
      </div>
    </PageDataProvider>
  )
}

export { MasterLayout }
