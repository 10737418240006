/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  navbar: String
  setNavbar: any
}

const MyCoursesNavbar: FC<Props> = ({navbar, setNavbar}) => {
  const intl = useIntl()

  return (
    <div className='navbarMyCourses'>
      <div className='container d-flex align-items-center'>
        <div
          className={`navbarMyCourses_Item ${
            navbar === 'activeCourses' ? 'selectedWithBorder' : ''
          }`}
          onClick={() => setNavbar('activeCourses')}
        >
          <span>{intl.formatMessage({id: 'POPLEADS.MENU_ACTIVE_COURSES'})}</span>
        </div>
        <div
          className={`navbarMyCourses_Item ${navbar === 'completed' ? 'selectedWithBorder' : ''}`}
          onClick={() => setNavbar('completed')}
        >
          <span>{intl.formatMessage({id: 'POPLEADS.MENU_COMPLETED'})}</span>
        </div>
        {/*<div className={`navbarMyCourses_Item ${navbar === 'favorites' ? 'selectedWithBorder' : ''}`} onClick={() => setNavbar('favorites')}>
                <span>{intl.formatMessage({ id: 'POPLEADS.MENU_FAVORITES' })}</span>
            </div>*/}
      </div>
    </div>
  )
}

export {MyCoursesNavbar}
